import "reflect-metadata";
import { container } from "tsyringe";
import { GoogleMaps } from './modules/google.maps';
import { Filter } from './modules/filter'
import { Images } from './modules/images';
import { Slideshow } from './modules/slideshow';

import AOS from 'aos';

const images = container.resolve(Images)
const googleMaps = container.resolve(GoogleMaps)
const filter = container.resolve(Filter)
const slideshow = container.resolve(Slideshow);

AOS.init({
    startEvent: 'DOMContentLoaded',
    duration: 800,
    //offset: 340
    //delay: 50,
});