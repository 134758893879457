import { injectable } from 'tsyringe'
import Swiper, { Navigation, Pagination } from 'swiper'

@injectable()
export class Slideshow {

    constructor() { 
        this._init();
    }

    private _init() {
        Swiper.use([Navigation, Pagination]);

        const mySwiper = new Swiper('.swiper-container', {
            // Optional parameters
            direction: 'horizontal',
            loop: true,

            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            // And if we need scrollbar
            scrollbar: {
                el: '.swiper-scrollbar',
            },
        })
    }
}
